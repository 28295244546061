import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import { Link } from "gatsby"

import Figure from "../figure"
import ImageGrid from "../imageGrid"
import VideoEmbed from "../videoEmbed"
import Table from "../table"
import CheckList from "../page-builder/checkList"
import { linkFormat, linkTest } from "../../helpers/links"

import styles from './blockContent.module.scss'

const serializers = {
  types: {
    block: (props) => {
      switch (props.node.style) {
        case 'address':
          return <address>{props.children}</address>

        default:
          return BaseBlockContent.defaultSerializers.types.block(props)
      }
    },
    figure (props) {
      return <Figure {...props.node} />
    },
    imageGrid (props) {
      return <ImageGrid {...props.node} />
    },
    videoEmbed (props) {
      return <VideoEmbed {...props.node} />
    },
    checkList (props) {
      return <CheckList {...props.node} />
    },
    multiColTable (props) {
      return <Table {...props.node} />
    }
  },
  marks: {
    link: ({children, mark}) => {
      const newTab = mark.newTab ? {["target"]: "_blank", ["rel"]: "noopener noreferrer"} : null
      const noFollow = mark.noFollow ? {["rel"]: "nofollow noopener noreferrer"} : null
      switch (mark.linkType) {
        case 'Internal':
          const internalLink = linkTest(mark.link)  === "internal" 
          if (internalLink) {
            return <Link to={linkFormat(mark.link)}>{children}</Link>
          } else {
            return <a href={mark.link} {...newTab} {...noFollow}>{children}</a>
          }

        case 'Email':
          const email = mark.link.replace(/^mailto:([^?]+).*/, '$1')
          return <a href={`mailto:${email}`}>{children}</a>

        case 'Telephone':
          const tel = mark.link.replace(/^tel:([^?]+).*/, '$1')
          return <a href={`tel:${tel}`}>{children}</a>

        default:
          const defaultLink = mark.link ? mark.link : mark.href
          const internal = linkTest(defaultLink)  === "internal" 
          return internal ? <Link to={linkFormat(defaultLink)}>{children}</Link> : <a href={defaultLink} {...newTab} {...noFollow}>{children}</a>
      }
    },
    sup: ({children}) => {
      return <sup>{children}</sup>
    }
  },
}

const BlockContent = ({ blocks }) => <div className={styles.blockContent}><BaseBlockContent blocks={blocks} serializers={serializers} /></div>

export default BlockContent
