import React, { useState } from "react"
import { Link } from "gatsby"

import Button from "../components/button"
import Logo from "../../assets/svg/logo-color.svg"
import styles from "./header.module.scss"
import navStyles from "./nav.module.scss"
import { useEffect } from "react"

const CustomHeader = ({ ctas, onMobile }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [buttons, setButtons] = useState([])
  const [lastButton, setLastButton] = useState(null)

  useEffect(() => {
    if (onMobile) {
      setButtons(ctas.link.slice(0, ctas.link.length - 1))
      setLastButton(ctas.link.slice(-1)[0])
      return
    }
    setButtons(ctas.link)
    setLastButton(null)
  }, [ctas, onMobile])

  const handleClick = () => {
    setMobileNavOpen(!mobileNavOpen)
  }

  return (
    <header className={`${styles.header} ${styles.isScrolled}`}>
      <div className={styles.headerInner}>
        <Link to="/">
          <span className={styles.headerLogoTitle}>
            PeopleFluent logo linking to homepage
          </span>
          <Logo className={styles.headerLogo} />
        </Link>
        <div className={navStyles.navSide}>
          <nav className={navStyles.nav} id="menu" hidden={!mobileNavOpen}>
            <ul className={navStyles.navList}>
              {buttons.map(button => (
                <li key={button._key}>
                  <Button
                    className={navStyles.navButton}
                    type={button.type}
                    to={button.link}
                    label={button.title}
                  />
                </li>
              ))}
            </ul>
          </nav>
          {lastButton && (
            <Button
              className={navStyles.navButton}
              type={lastButton.type}
              to={lastButton.link}
              label={lastButton.title}
            />
          )}
          {!!buttons.length && (
            <button
              className={navStyles.navTrigger}
              aria-expanded={mobileNavOpen}
              aria-controls="menu"
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            >
              <div className={navStyles.navTrigger__title}>Menu</div>
              <span></span>
            </button>
          )}
        </div>
      </div>
    </header>
  )
}

export default CustomHeader
