import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import SEO from "../../components/seo"
import PageBuilder from "../../components/page-builder"
import Layout from "../../modules/layout"
import SimpleHero from "../../modules/simpleHero"
import RichText from "../../components/block-content"
import Form from "../../modules/form"
import Hero from "../../modules/hero"
import Logo from "../../../assets/svg/logo.svg"
import CustomHeader from "../../modules/customHeader"

import styles from "./landingPageTemplate.module.scss"
import "./landingPageTemplate.module.scss"

export const query = graphql`
  query($slug: String) {
    sanityLandingPage(slug: { current: { eq: $slug } }) {
      title
      _rawSubtitle
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawHeader
      formTitle
      _rawFormSubtitle
      formLink
      disableOldForm
      _rawHero
      _rawContent(resolveReferences: { maxDepth: 10 })
      hero {
        hideHero
        heading
        redHero
        reducePadding
        illustration
        customIllustration {
          alt
          caption
          image {
            asset {
              url
            }
          }
        }
        heroVideo
      }
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

export default props => {
  const page = props.data.sanityLandingPage
  const title = page.seo?.metaTitle || page.title
  const description = page.seo?.description
  const image = page.seo?.openGraphImage?.asset?.url
  const hasGreyBackground =
    page?._rawContent &&
    (page._rawContent[0]?.backgroundColor === "Grey" ||
      page._rawContent[0]?.roundedTop == true)

  const OldForm = () => (
    <div className={styles.wrapper}>
      <div className="grid">
        <div className="gridItem large-6">
          <SimpleHero
            heading={page.title}
            tagline={page._rawSubtitle}
            withoutBg
            splitLayout
          />
          <RichText blocks={page._rawBody} />
        </div>
        <div className="gridItem large-6">
          <Form
            title={page.formTitle}
            link={page.formLink}
            split="true"
            subtitle={page._rawFormSubtitle}
          />
        </div>
      </div>
    </div>
  )

  const LogoSection = () =>
    page._rawHeader?.customNav || !page._rawHeader?.disableNav ? null : (
      <Logo className={styles.logo} />
    )

  const HeroSection = () => (
    <>
      <LogoSection />
      <Hero
        {...page.hero}
        tagline={page._rawHero?.tagline}
        ctas={page._rawHero?.ctas}
        illustration={page.hero.illustration}
        customIllustration={page.hero.customIllustration}
        heroVideo={page.hero.heroVideo}
        layout="asymmetric"
        disableDecorations={true}
        hasGreyBackground={hasGreyBackground}
      />
    </>
  )

  return (
    <>
      <Layout
        disableHeader={
          page._rawHeader?.disableNav || page._rawHeader?.customNav
        }
        transition={false}
      >
        <Helmet>
          {page.seo && page.seo.noIndex && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <SEO title={title} description={description} image={image} />
        {page._rawHeader?.customNav ? (
          <CustomHeader
            ctas={page._rawHeader?.ctas}
            onMobile={page._rawHeader.onMobile}
          />
        ) : null}
        {page.hero && !page.hero.hideHero ? <HeroSection /> : null}
        <PageBuilder blocks={page._rawContent} />
        {!page.disableOldForm ? <OldForm /> : null}
      </Layout>
    </>
  )
}
